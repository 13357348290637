import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BackGlossary from '../../../../components/BackGlossary';
import BannerSimple from '../../../../components/BannerSimple';

const GlossaryPageContent = () => {
  return (
    <div className="md:px-5 max-w-[1285px]">
      <div className="mb-8 text-xl font-bold text-left md:text-2xl">Standing Access</div>
      <p className="mb-12 text-lg text-left">
        Standing access pertains to the continuous and unimpeded access to systems or resources, even when not
        immediately necessary. This form of access is commonly extended to privileged users like system administrators,
        and at times regular users for specific functions.
      </p>

      <p className="mb-12 text-lg text-left">
        Standing access opens up a significant security vulnerability. In the event standing access credentials are
        compromised, unauthorized individuals may gain access to sensitive systems or resources,leading to data
        breaches, malware infiltration, and operational disruption.
      </p>

      <p className="mb-12 text-lg text-left">
        To counteract the inherent risks tied to standing access, organizations should enforce the principle of zero
        standing and least privileged access, keeping users at zero standing access most of the time, and only elevating
        them to least privilege at the times required for them to perform their assigned tasks. Following this pattern
        minimizes the identity attack surface.
      </p>

      <p className="mb-6 text-lg text-left">
        Employing an ITDR solution like Trustle to remediate and respond to all standing access entitlements and
        continue to monitor for ongoing anomalies is the best thing an organization can do to alleviate standing access
        and reduce attack surface.
      </p>

      <p className="mb-12 text-lg text-left">
        To quickly detect and respond to users within your organization with standing privileged access, contact one of
        our security specialists today.
      </p>
    </div>
  );
};

const StandingAccess = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `Standing Access`;
  const description = `Explore our site to discover detailed information about ${title}`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={description} />
          </div>
        </section>

        <section className="pb-16 ">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <GlossaryPageContent />
            </div>
          </div>
          <BannerSimple
            title="Curious how Trustle works?"
            description={'Get an inside look at Trustle. Schedule a demo with us today.'}
          />
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default StandingAccess;
